<script>
import Spinner from "@/components/ui/Spinner";
import defaultTaskService from "@/services/default-task";

const CONFIG_TYPE = "vitals";
const VITAL_CONFIG = {
  name: "",
  description: "",
  answerValues: [
    {
      name: "",
      measurement: {
        green: {
          min: "",
          max: "",
        },
        yellow: {
          min: "",
          max: "",
        },
        red: {
          min: "",
          max: "",
        },
      },
    },
  ],
};

export default {
  name: "VitalDefaultDetail",

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      isUpdate: this.$route.name === "update-vital",
      vitalConfigId: this.$route.params.id,
      vitalConfig: VITAL_CONFIG,
      isFetchingVitalConfig: false,
      isPostingVitalConfig: false,
      showAnswerError: false,
    };
  },

  created() {
    this.getVitalConfig();
  },

  watch: {
    "$route.name"(name) {
      this.isUpdate = name === "update-vital";
      if (!this.isUpdate) {
        this.vitalConfig = Object.assign({}, VITAL_CONFIG);
      }
    },
    "$route.params.id"(id) {
      this.vitalConfigId = id;
      this.getVitalConfig();
    },
  },

  methods: {
    submit() {
      this.createOrUpdateVitalConfig();
    },

    createOrUpdateVitalConfig() {
      this.isPostingVitalConfig = true;
      const serviceCall = this.isUpdate
        ? defaultTaskService.update
        : defaultTaskService.create;
      return serviceCall(this.vitalConfig, CONFIG_TYPE)
        .then(() => {
          this.$router.push({
            name: "treatment-vital",
          });

          if (this.isUpdate) {
            this.$bus.$emit("vital-config-update", this.vitalConfig);
          } else {
            this.$bus.$emit("vital-config-create", this.vitalConfig);
          }
        })
        .finally(() => {
          this.isPostingVitalConfig = false;
        });
    },

    deleteVitalConfig() {
      this.isLoading = true;
      defaultTaskService
        .delete(this.vitalConfigId, CONFIG_TYPE)
        .then(() => {
          this.$router.push({
            name: "treatment-vital",
          });
          this.$bus.$emit("vital-config-removed", this.vitalConfigId);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getVitalConfig() {
      if (this.isUpdate) {
        this.isLoading = true;
        this.isFetchingVitalConfig = true;
        defaultTaskService
          .getById({ taskID: this.vitalConfigId, taskType: CONFIG_TYPE })
          .then((vitalConfig) => {
            this.vitalConfig = vitalConfig;
          })
          .finally(() => {
            this.isLoading = false;
            this.isFetchingVitalConfig = false;
          });
      }
    },

    addAnswer() {
      this.showAnswerError = false;
      const lastAnswer =
        this.vitalConfig.answerValues[this.vitalConfig.answerValues.length - 1];

      if (
        !!lastAnswer &&
        (!lastAnswer.measurement.green.min ||
          !lastAnswer.measurement.green.max ||
          !lastAnswer.measurement.yellow.min ||
          !lastAnswer.measurement.yellow.max ||
          !lastAnswer.measurement.red.min ||
          !lastAnswer.measurement.red.max ||
          !lastAnswer.name)
      ) {
        this.showAnswerError = true;
        return;
      }

      this.vitalConfig.answerValues.push({
        name: "",
        measurement: {
          green: {
            min: "",
            max: "",
          },
          yellow: {
            min: "",
            max: "",
          },
          red: {
            min: "",
            max: "",
          },
        },
      });
    },

    removeAnswer(index) {
      this.showAnswerError = false;
      this.vitalConfig.answerValues.splice(index, 1);
    },
  },
};
</script>

<template lang="pug">
  section.detail(v-if="isLoading")
    spinner
  ValidationObserver(v-else v-slot="{handleSubmit}")
    form.detail(@submit.prevent="handleSubmit(submit)")
      header.detail__header
        h2.detail__headline {{ vitalConfig.name | capitalize }}
        .detail__actions(v-auth="'task-default.create'")
          el-button(v-if="isUpdate" type="danger" :disabled="vitalConfig.isNative" @click="deleteVitalConfig") Borrar
          el-button(type="primary" native-type="submit" :loading="isPostingVitalConfig") Guardar
      p.subtitle Los campos con (*) son obligatorios
      .modal__content(v-if="isFetchingVitalConfig")
        spinner

      .detail__content(v-else)
        .row
          fieldset.detail__field
            label.label * Nombre
            ValidationProvider(name="Nombre" rules="required" v-slot="{errors}")
              el-input(
                v-model="vitalConfig.name"
                autofocus
                :disabled="vitalConfig.isNative"
              )
              span.has-error {{ errors[0] }}
        .row
          fieldset.detail__field
            label.label Descripción
            el-input(
              type="textarea"
              v-model="vitalConfig.description"
              rows="3"
              maxlength="250"
            )
        .answers(v-for="(answer, index) in vitalConfig.answerValues")
          .row
            fieldset.detail__field
              label.label Nombre Respuesta {{index + 1}}
              ValidationProvider(name="Nombre" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.name"
                )
                span.has-error {{errors[0]}}

          .row
            fieldset.detail__field
              el-checkbox(v-model="answer.compareToLastMeasurement")
                | Comparar contra última medición

          .row
            fieldset.detail__field
              label.label * Correcto Mínimo
              ValidationProvider(name="Correcto Min" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.green.min"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Correcto Máximo
              ValidationProvider(name="Correcto Max" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.green.max"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

          .row
            fieldset.detail__field
              label.label * Tolerable Mínima
              ValidationProvider(name="Desviación tolerable Min" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.yellow.min"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Tolerable Máxima
              ValidationProvider(name="Desviación tolerable Max" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.yellow.max"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

          .row
            fieldset.detail__field
              label.label * Anormal Mínimo
              ValidationProvider(name="Desviación anormal Min" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.red.min"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

            fieldset.detail__field
              label.label * Anormal Máximo
              ValidationProvider(name="Desviación anormal Max" rules="required" v-slot="{errors}")
                el-input(
                  v-model="answer.measurement.red.max"
                  type="number"
                  step="any"
                )
                  template(v-if="answer.compareToLastMeasurement" slot="append")
                    | %
                span.has-error {{ errors[0] }}

          .modal__row
            fieldset.modal__field
              small
                a(@click="removeAnswer(index)") &times; Eliminar Respuesta {{ index + 1 }}

        
        .modal__row
          fieldset.modal__field
            span.days-error(v-if="!!vitalConfig.answerValues && showAnswerError") Debes completar la respuesta para poder agregar una nueva

        .modal__row.days__add
          fieldset.modal__field  
            a(@click="addAnswer") + Agregar Respuesta
</template>

<style lang="scss">
.detail__content {
  display: flex;
  flex-direction: column;
}

.subtitle {
  text-align: right;
  margin: 10px;
}
</style>
