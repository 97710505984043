<script>
import treatmentService from "@/services/treatment";
import segmentService from "@/services/segment";
import medicalSpecialtyService from "@/services/medical-specialty";

import Spinner from "@/components/ui/Spinner";

export default {
  name: "TreatmentDetail",

  components: {
    Spinner,
  },

  data() {
    return {
      treatmentId: this.$route.params.id,
      treatment: {},
      isUpdate: this.$route.name === "update-treatment",
      isFetchingTreatment: false,
      isPostingTreatment: false,
      medicalSpecialties: [],
    };
  },

  created() {
    this.getTreatment();
    this.getMedicalSpecialties();
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    escape(event) {
      if (event.keyCode == 27) this.goToRoute("treatment");
    },

    submit() {
      this.createOrUpdateTreatment();
    },

    createOrUpdateTreatment() {
      const eventName = this.isUpdate
        ? "Treatment Updated"
        : "Treatment Created";
      segmentService.track({ name: eventName });

      this.isPostingTreatment = true;
      const serviceCall = this.isUpdate
        ? treatmentService.update
        : treatmentService.create;

      return serviceCall(this.treatment)
        .then((treatment) => {
          this.treatmentId = treatment._id;
          this.$bus.$emit("treatment-update", treatment || this.treatment);
        })
        .then(() => {
          if (this.isUpdate) {
            this.goToRoute("treatment");
          } else {
            this.goToRoute("treatment-tasks", this.treatmentId);
          }
        })
        .finally(() => {
          this.isPostingTreatment = false;
        });
    },

    getTreatment() {
      if (this.isUpdate) {
        this.isFetchingTreatment = true;
        treatmentService
          .getById(this.treatmentId)
          .then((treatment) => {
            this.treatment = treatment;
          })
          .finally(() => {
            this.isFetchingTreatment = false;
          });
      }
    },

    async getMedicalSpecialties() {
      const medicalSpecialties =
        await medicalSpecialtyService.getMedicalSpecialties();
      this.medicalSpecialties = medicalSpecialties;
    },

    goToRoute(name, id = "") {
      this.$router.push({ name, params: { id } });
    },

    getButtonName() {
      if (this.isUpdate) {
        return "Actualizar Protocolo";
      }
      return "Nuevo Protocolo";
    },
  },
};
</script>

<template lang="pug">
  ValidationObserver(v-slot="{handleSubmit}")
    form.modal(@submit.prevent="handleSubmit(submit)")
      header.modal__header
        h2.modal__title {{ getButtonName() }}
        .modal__actions
          el-button(type="info" @click="goToRoute('treatment')") Cancelar
          el-button.border(type="primary" native-type="submit" :loading="isPostingTreatment") Guardar
      p.modal__subtitle Los campos con (*) son obligatorios
      .modal__content(v-if="isFetchingTreatment")
        spinner
      .modal__content(v-else)
        // Treatment Info Section
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="description")
              h3.sign__title Información del Protocolo
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  ValidationProvider(name="Nombre" , rules="required", v-slot="{ errors }")
                    label.label * Nombre del Protocolo:
                    el-input(v-model="treatment.name", autofocus)
                    span.has-error {{ errors[0] }}
              .modal__row
                fieldset.modal__field
                  label.label Descripción:
                  el-input(
                    type="textarea"
                    v-model="treatment.description"
                    name="Descripción"
                    rows="10"
                  )
              .modal__row
                fieldset.modal__field
                  label.label Especialidad:
                  el-select(
                    v-model="treatment.medicalSpecialty"
                    placeholder="Seleccione especialidad"
                    filterable
                    clearable
                  )
                    el-option(v-for='medicalSpecialty in medicalSpecialties' :key='medicalSpecialty._id' :value='medicalSpecialty._id' :label='medicalSpecialty.name')

</template>
