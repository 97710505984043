<script>
import { TASKS_TYPES } from "@/services/constants";

export default {
  name: "TaskCard",

  props: {
    task: {
      type: Object,
    },
    taskType: {
      type: String,
      default: "",
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "treatment",
    },
  },

  data() {
    return {
      TASKS_TYPES,
      showDelete: false,
    };
  },

  computed: {
    getIcon() {
      if (this.isCreate) {
        return "add";
      }

      return TASKS_TYPES[this.task.type].icon;
    },

    daysTask() {
      if (this.task.periodization.type === "range") {
        const rangeDays =
          this.task.periodization.toDay - this.task.periodization.fromDay + 1;
        return rangeDays === 1 ? `${rangeDays} día` : `${rangeDays} días`;
      }

      const explicitDays = this.task.periodization.days;
      return explicitDays.length === 1
        ? `Día ${explicitDays[0]}`
        : `Días ${explicitDays.join(", ")}`;
    },
  },

  methods: {
    onCardClick() {
      if (this.isCreate) {
        this.goToCreateTask();
        return;
      }

      if (!this.task.type) {
        return;
      }

      const name = `update-${this.type}-task`;
      const taskID = this.task._id;
      const taskType = this.task.type;
      const params = { taskID, taskType };
      this.$router.push({ name, params });
    },

    onTaskToDelete(e) {
      e.stopPropagation();
      this.$emit("onDelete", this.task);
    },

    goToCreateTask() {
      this.$router.push({
        name: `create-${this.type}-task`,
        params: { taskType: this.taskType },
      });
    },

    onMouseEnter() {
      if (!this.isCreate) {
        this.showDelete = true;
      }
    },

    onMouseLeave() {
      if (!this.isCreate) {
        this.showDelete = false;
      }
    },
  },
};
</script>
<template lang="pug">
  a.card(@click="onCardClick" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" :class="{ 'is-create': isCreate }")
    .card-task__delete-button.icon-fill(v-show="!isCreate && showDelete && canDelete", @click="onTaskToDelete")
      micon(name="delete_forever")
    .header-wrapper(v-if="!isCreate")
      small.time-ago(v-if="task.type !== 'reports'") {{ daysTask }}
      .tag.alert-badge.card__tag(:class="task.type") {{ TASKS_TYPES[task.type].name }}
    .avatar.card__avatar
      micon(:name="getIcon")
    h6.card__title(v-if="!isCreate") {{ task.name | capitalize }}
</template>

<style lang="scss" scoped>
.card.is-create {
  min-height: 159px;
}

.tag.alert-badge {
  &.vitals {
    border-color: $vital;
    color: $vital;
  }
  &.checkins {
    border-color: $checkin;
    color: $checkin;
  }
  &.medications {
    border-color: $medication;
    color: $medication;
  }
  &.appointments {
    border-color: $appointment;
    color: $appointment;
  }
  &.recommendations {
    border-color: $recommendation;
    color: $recommendation;
  }
  &.exercises {
    border-color: $exercise;
    color: $exercise;
  }
  &.reports {
    border-color: $report;
    color: $report;
  }
}
</style>
