import debounce from "debounce";
import helperService from "@/services/helper";
import defaultTaskService from "@/services/default-task";
import Spinner from "@/components/ui/Spinner";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      tasks: [],
      isLoading: false,
      searchInput: "",
    };
  },

  created() {
    this.getTasks();
  },

  methods: {
    getTasks(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      defaultTaskService
        .get(this.configType, defaultQuery)
        .then((res) => {
          this.tasks = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    debounceInput: debounce(function () {
      this.getTasks(
        helperService.buildQuery({
          offset: 0,
          searchTask: this.searchInput,
          queryProperties: ["name", "description"],
        })
      );
    }, DEBOUNCE_MS_TIMEOUT),

    onPageChange() {
      this.getTasks();
    },

    edit(taskID) {
      this.$router.push({
        name: "update-task",
        params: { taskType: this.configType, taskID },
      });
    },

    remove(task) {
      this.$confirm(
        `¿Desea eliminar la tarea predeterminada: ${task.name}?`,
        "Eliminar",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => defaultTaskService.delete(task._id, this.configType))
        .then(() => this.getTasks())
        .catch(() => {});
    },
  },
};
