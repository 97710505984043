<script>
import Spinner from "@/components/ui/Spinner";

import defaultTaskService from "@/services/default-task";

const CONFIG_TYPE = "vitals";
const vitalIcons = {
  bubbles: "bubble_chart",
  hospital: "local_hospital",
};

export default {
  name: "VitalDefaultList",

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      vitals: [],
    };
  },

  created() {
    this.getVitals();
  },

  updated() {
    this.$bus.$on("vital-config-update", (vital) => {
      this.vitals = this.vitals.map((v) => {
        if (v._id === vital._id) {
          return vital;
        }
        return v;
      });
      this.$router.push({
        name: "update-vital",
        params: { id: this.vitals[0]._id },
      });
    });
    this.$bus.$on("vital-config-create", () => {
      this.isLoading = true;
      defaultTaskService
        .get(CONFIG_TYPE)
        .then((res) => {
          this.vitals = res.docs;
          this.$router.push({
            name: "update-vital",
            params: { id: this.vitals[0]._id },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
    this.$bus.$on("vital-config-removed", (vital) => {
      this.vitals = this.vitals.filter((v) => v._id !== vital);
      if (this.vitals.length) {
        this.$router.push({
          name: "update-vital",
          params: { id: this.vitals[0]._id },
        });
      }
    });
  },

  beforeDestroy() {
    this.$bus.$off("vital-config-update");
    this.$bus.$off("vital-config-create");
    this.$bus.$off("vital-config-removed");
  },

  methods: {
    getVitals() {
      this.isLoading = true;
      defaultTaskService
        .get(CONFIG_TYPE)
        .then((res) => {
          this.vitals = res.docs;
          if (this.vitals.length) {
            this.$router.push({
              name: "update-vital",
              params: { id: this.vitals[0]._id },
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goToConfigVital(id) {
      this.$router.push({ name: "update-vital", params: { id } });
    },
    getIcon(name) {
      switch (name) {
        case "bubbles":
          return vitalIcons[name];
        case "hospital":
          return vitalIcons[name];
        default:
          return name;
      }
    },
  },
};
</script>

<template lang="pug">
  section
    header.headline
      //- .headline__title
        h1
          | Registros
          small(v-if="vitals.length")  ({{ vitals.length }})
        hr

    article.row(v-if="isLoading")
      spinner
    article.row(v-else)
      nav.sidebar
        a.sidebar__link(v-for="vital in vitals", @click="goToConfigVital(vital._id)", :class="{ 'is-active': $route.params.id === vital._id }")
          micon(:name="getIcon(vital.icon)")
          strong.sidebar__text {{ vital.name | capitalize }}
        router-link.sidebar__link(:to="{ name: 'create-vital' }")
          micon(name="add")
          strong.sidebar__text Crear

      .box.box--full.box--with-subnav
        router-view
</template>
