<script>
import pagination from "@/mixins/pagination";
import taskDefaultList from "@/mixins/taskDefaultList";

export default {
  name: "ExercisesDefaultList",

  mixins: [pagination, taskDefaultList],

  data() {
    return {
      configType: "exercises",
    };
  },
};
</script>

<template lang="pug">
  section
    header.headline
      //- .headline__title
        h1
          | Ejercicios
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        el-input.search__input(prefix-icon="el-icon-search" v-on:input="debounceInput" v-model="searchInput" placeholder="Buscar" :clearable="true")
        router-link.button.button--blue(:to="{name: 'create-task', params: { taskType: 'exercises' }}" v-auth="'task-default.create'")
          micon(name="add_circle_outline").button__icon
          span.button__text Nuevo Ejercicio
    .box.box--with-subnav
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Tipo de Ejercicio
              th Duración
              th Repeticiones
              th Series
              th Peso
              th Descripción
              th Acciones
          tbody
            spinner(v-if="isLoading && !tasks.length")
            tr(v-else, v-for="(e, index) in tasks", :key="e._id")
              td {{ e.name }}
              td {{ e.tags && e.tags.toString() }}
              td {{ e.duration }}
              td {{ e.repetitions }}
              td {{ e.series }}
              td {{ e.weight }}
              td {{ e.description }}
              td.actions
                .actions-container
                  button.button.button--action(@click="edit(e._id)")
                    micon(name="edit")
                  button.button.button--action(@click="remove(e)")
                    micon(name="delete_forever")
      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit",
        :total="pagination.total",
        @pagination="setPagination"
      )
</template>
